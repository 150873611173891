<template>
  <el-dialog class="info-div800" style="margin-top: 5vh">
      <div style="padding:0 8%;">
          <el-form-item label="标题" label-width="100px">
            <el-input v-model="Info.title" autocomplete="off" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="资讯类型" label-width="100px">
            <el-select v-model="Info.informationTypeId" placeholder="请选择资讯类型">
              <el-option v-for="(f,index) in TypeList" :key="index" :label="f.name" :value="f.id" />
            </el-select>
          </el-form-item>
        <div class="flex justify-between">
          <div class="flex justify-start align-start">
            <div style="width: 100px">导图</div>
            <el-upload
                class="avatar-uploader"
                :action="'/manage/file/upload?Authorization=Bearer ' +token"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="Info.img" :src="'/manage'+Info.img" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </div>
        </div>
        <div class="marginTop15px">
          <el-form-item label="描述" label-width="100px">
            <el-input v-model="Info.desc" type="textarea" rows="4" autocomplete="off" placeholder="请输入" />
          </el-form-item>
        </div>
        <div style="height: 420px">
          <el-form-item label="内容" label-width="100px">
            <editor @getText="getText" ref="editor" :content="Info.content" style="height: 300px;"></editor>
          </el-form-item>
        </div>
        <div class="dialog-footer">
          <el-button @click="closeDiv">取消</el-button>
          <el-button type="primary" @click="subData">提交</el-button>
        </div>
      </div>
  </el-dialog>
</template>
<script>
import {informationEdit,getInformationTypeAll,getInformationContent} from "@/api/notice.js"
import editor from "@/components/editor.vue";
export default {
  components:{
    editor
  },
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:{},
      TypeList:[],
    }
  },
  mounted() {

  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    getInfo(row){//清空表单
      this.getServerList();
      if(row){
        if(row.id){
          this.getContentInfo(row.id);
        }
      }
    },
    getServerList(){
      getInformationTypeAll().then(res=>{
        this.TypeList=res.data;
      })
    },
    getContentInfo(id){
      getInformationContent(id).then(res=>{
        this.Info=res.data;
        this.setText();
      })
    },
    subData(){
      informationEdit(this.Info).then((res)=>{
        if(res.success){this.$root.ElMessage({type:'success',message:res.message});this.$emit('search')}else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    beforeAvatarUpload(e){
      console.log(e);
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.img=e.data;
    },
    getText(html){
      this.Info.content=html;
    },
    setText(){
      this.$refs.editor.setText(this.Info.content);
    },
  }
}
</script>

<style scoped>
  .info-title{padding: 10px;text-align: left;font-size: 14px;color: #000000}
  .head-text{margin-left: 10px;color: #1890FF;font-size: 14px}
  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 150px;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 180px;
    height: 150px;
    display: block;
  }
  .el-icon .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 150px;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 180px;
    height: 150px;
    display: block;
  }
</style>