<template>
  <div>
    <div>
      <div class="flex justify-between paddingTD20">
        <div><el-button type="primary" @click="showEdit('')">新增资讯</el-button></div>
        <div class="flex search-text">
          <el-input v-model="pageInfo.title" placeholder="标题" style="margin-right: 60px;width: 200px" />
          <el-button type="primary" @click="reset(null)">重置</el-button>
          <el-button type="primary" @click="search"><el-icon><Search /></el-icon> <span class="marginLeft5">搜索</span></el-button>
        </div>
      </div>
      <div class="project-table">
<!--        <div style="background-color: rgba(0,0,0,0.25);width: 100%;height: 1px;"></div>-->
        <el-table :data="tableData" row-key="id" @selection-change="handleSelectionChange"
                  :header-cell-style="{'text-align':'center','border-top':'1px solid #000019','border-bottom':'1px solid #000019'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="id" label="访问ID" />
          <el-table-column prop="" label="导图">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <img :src="'/manage'+scope.row.img" style="width: 100px;height: 70px">
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题" width="200">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <div class="text-out-hide" :title="scope.row.title">{{scope.row.title}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="分类">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span>{{scope.row.informationType?scope.row.informationType.name:''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="发布时间" width="180" />
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" size="small" @click="showEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <editDiv v-model="dialogFormVisible" :Row="Info" @closeDiv="closeDiv" @search="search" ref="editDiv"></editDiv>
  </div>
</template>

<script>
import editDiv from "@/view/notice/informationEdit.vue"
import {getInformationList} from "@/api/notice.js"
export default {
  name: "informationList",
  components:{
    editDiv
  },
  data(){
    return{
      tableData:[],
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        title:'',
        page:1,
        total:0
      },
      exportIds:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    reset(){
      this.pageInfo.title=''
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
      this.dialogFormVisible=false;
    },
    getList(){
      getInformationList(this.pageInfo).then(res=>{
        console.log(res);
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(row){
      this.dialogFormVisible=true;
      this.Info=row;
      this.$refs.editDiv.getInfo(row);//调用子组件方法
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    handleSelectionChange(e){//多选用户
      let exportIds='';
      for(let i in e){
        let id=e[i].id;
        if(i==0){
          exportIds=id;
        }else{
          exportIds=exportIds+","+id;
        }
      }
      this.exportIds=exportIds;
    },
    exportUser(){//批量导出
      let url="/manage/user/export?Authorization=Bearer "+localStorage.getItem('token')+"&exportIds="+this.exportIds;
      location.href=url;
    }
  }
}
</script>

<style scoped>

</style>